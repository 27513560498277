<template>
  <div>

    <b-overlay
      :show="$apollo.loading || mutationLoading"
      rounded="sm"
    >
      <b-card
        class="text-left"
      >
        <b-card-title class="lead ">
          Manage Workshops {{ $route.params.mid ? `for ${rows[0].programs_operationstable.title}` : '' }}
          <b-button
            v-if="$route.params.mid"
            variant="outline-primary ml-1"
            size="xs"
            @click="$router.replace(`/programs/${$route.params.id}/manage#Workshops`)"
          >
            Show All
          </b-button>
        </b-card-title>

        <!-- search input -->
        <div class="custom-search d-flex justify-content-start">
          <b-form-group
            class="form-inline"
            label="Search"
            label-size="sm"
          >
            <b-form-input
              v-model="searchTerm"
              class="d-inline-block mr-1"
              placeholder="Search Workshops"
              type="text"
            />
            <b-button
              v-b-modal.scheduleMeetingModal
              variant="primary mr-1"
            >
              <feather-icon icon="PlusIcon" />
              Add Workshop
            </b-button>

          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          :rows="rows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Action -->
            <span
              v-if="props.column.field === 'action'"
              class="d-flex flex-wrap"
            >
              <b-button
                v-b-tooltip="'Manage Exercises'"
                class="mr-50"
                size="sm"
                variant="outline-primary"
                :href="`/programs/${$route.params.id}/manage/${rows[props.row.originalIndex].id}#Exercises`"
              >
                Exercises
              </b-button>
              <b-button
                v-if="rows[props.row.originalIndex].meeting"
                v-b-tooltip="'Join Workshop'"
                class="mr-50"
                size="sm"
                variant="outline-primary"
                target="_window"
                :href="rows[props.row.originalIndex].meeting"
              >
                Join
              </b-button>
              <b-button
                v-if="rows[props.row.originalIndex].recording"
                v-b-tooltip="'View Recording'"
                class="mr-50"
                size="sm"
                variant="outline-primary"
                target="_window"
                :href="rows[props.row.originalIndex].recording"
              >
                Recording
              </b-button>
              <b-button
                v-b-tooltip="'Update Links'"
                class="mr-50"
                size="sm"
                variant="outline-primary"
                @click="selectedRow = props.row.originalIndex; selectedoperationid = rows[props.row.originalIndex].id; $bvModal.show('updateLinks')"
              >
                <span
                  v-if="!rows[props.row.originalIndex].meeting || !rows[props.row.originalIndex].recording"
                >
                  Update Links
                </span>
                <feather-icon
                  icon="LinkIcon"
                />
              </b-button>
              <b-button
                v-b-tooltip="'Delete'"
                size="sm"
                variant="outline-danger"
                @click="selectedRow = props.row.originalIndex; selectedoperationid = rows[props.row.originalIndex].id; deleteWorkshop()"
              >
                <feather-icon
                  icon="TrashIcon"
                />
              </b-button>
            </span>

            <span v-else-if="props.column.field === 'buttons'">

              <b-button
                :href="prependHttps(props.row.meeting_link)"
                class="mb-25 mr-25"
                size="sm"
                target="_blank"
                variant="outline-primary"
              >Join meeting</b-button>
              <b-button
                v-if="props.row.recording_link"
                :href="prependHttps(props.row.recording_link)"
                size="sm"
                target="blank"
                variant="outline-primary"
              >View recording</b-button>
            </span>

            <span v-else-if="props.column.field === 'date'">
              <span
                v-if="rows[props.row.originalIndex].begindate_timestamp && rows[props.row.originalIndex].enddate_timestamp"
                v-b-tooltip="'Click to Reschedule'"
                style="cursor:pointer"
                @click="selectedRow = props.row.originalIndex; selectedoperationid = rows[props.row.originalIndex].id; beginTime = rows[props.row.originalIndex].begindate_timestamp; endTime = rows[props.row.originalIndex].enddate_timestamp; $bvModal.show('updateSchedule')"
              >
                {{
                  formatDate(rows[props.row.originalIndex].begindate_timestamp)
                }} - {{ formatDate(rows[props.row.originalIndex].enddate_timestamp) }}
              </span>
              <b-button
                v-else
                class="mb-25 mr-25"
                size="sm"
                variant="outline-primary"
                @click="selectedRow = props.row.originalIndex; selectedoperationid = rows[props.row.originalIndex].id; $bvModal.show('updateSchedule')"
              >
                Schedule
              </b-button>
            </span>
            <span v-else-if="props.column.field === 'title'">
              {{ props.row.title }}
              <b-button
                size="sm"
                variant="outline-primary"
                class="ml-2"
                @click="selectedRow = props.row.originalIndex; title = props.row.title; description = props.row.description; selectedoperationid = rows[props.row.originalIndex].id; beginTime = rows[props.row.originalIndex].begindate_timestamp; endTime = rows[props.row.originalIndex].enddate_timestamp; $bvModal.show('updateTitleDescDateModal')"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </span>

            <span v-else-if="props.column.field === 'mentors'">

              <b-badge
                v-for="mentor in props.row.mentor_assignments"
                :key="mentor.programs_partnerstable.id"
                variant="light-primary"
                class="mr-1"
              >{{ getMentorFullName(mentor) }}</b-badge>
              <b-button
                v-b-tooltip="'Add Mentors'"
                size="sm"
                variant="outline-primary"
                style="float: right;"
                @click="selectedRow = props.row.originalIndex; selectedoperationid = rows[props.row.originalIndex].id; assignedmentors = rows[props.row.originalIndex].mentor_assignments; $apollo.queries.mentors.refetch(); $bvModal.show('addMentor')"
              >
                <feather-icon
                  icon="SettingsIcon"
                />
              </b-button>
            </span>

            <span v-else-if="props.column.field === 'ratings'">
              <b-link
                style="text-decoration: underline; cursor: pointer; color: #7367F0;"
                @click="selectedRow = props.row.originalIndex; selectedoperationid = rows[props.row.originalIndex].id; $apollo.queries.ratings.refetch(); $bvModal.show('ratingstable')"
              >
                {{
                  (props.row.ratings_tables_aggregate.aggregate.avg.rating != null ? props.row.ratings_tables_aggregate.aggregate.avg.rating.toFixed(2) : '0.00')
                }} ({{ props.row.ratings_tables_aggregate.aggregate.count || 0 }})
              </b-link>
            </span>

            <span v-else-if="props.column.field === 'status'">
              <b-badge
                :variant="statusVariant(props.formattedRow[props.column.field])"
                class="mb-25"
              >{{ props.formattedRow[props.column.field] }}</b-badge>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template
            #column-filter="{ column, updateFilters }"
          >
            <span v-if="column.field === 'operation'">
              <treeselect
                :append-to-body="true"
                :normalizer="filterNormalizer"
                :options="operations"
                class="form-control p-0 border-0"
                placeholder="Select Phase, Subphase or Activities"
                style="border-collapse: separate"
                @input="(val) => updateFilters(column, val)"
              />
            </span>

          </template>
          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10','20','30']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :per-page="pageLength"
                  :total-rows="props.total"
                  :value="1"
                  class="mt-1 mb-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </b-overlay>
    <b-modal
      id="scheduleMeetingModal"
      ok-title="Save"
      no-close-on-backdrop
      size="lg"
      :ok-disabled="!module || !title || !description"
      title="Add Workshop"
      @ok="addMeeting"
    >
      <b-row>

        <b-col md="6">
          <b-form-group
            label="Module"
            label-for="module"
          >
            <treeselect
              id="module"
              v-model="module"
              :normalizer="normalizer"
              :options="operations"
              placeholder="Select Module"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Title"
            label-for="title"
          >
            <b-form-input
              id="title"
              v-model="title"
              placeholder="Workshop title"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Description"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="description"
              placeholder="Workshop Description"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="updateTitleDescDateModal"
      title="Update Details"
      ok-title="Save"
      @ok="handleUpdateTitleDescDate"
    >
      <b-form-group
        label="Title"
        label-for="updateTitle"
      >
        <b-form-input
          id="updateTitle"
          v-model="title"
          placeholder="Workshop title"
        />
      </b-form-group>
      <b-form-group
        label="Description"
        label-for="updateDescription"
      >
        <b-form-textarea
          id="updateDescription"
          v-model="description"
          placeholder="Workshop description"
        />
      </b-form-group>
      <b-row>
        <!-- start date and time -->
        <b-col md="6">
          <b-form-group
            class="mb-2"
            label="Start date & time"
            label-for="StartDateAndTime"
          >
            <flat-pickr
              id="StartDateAndTime"
              v-model="beginTime"
              :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
              class="form-control"
              placeholder="Select start date & tIme"
            />
          </b-form-group>
        </b-col>
        <!-- end date and time -->
        <b-col md="6">
          <b-form-group
            class="mb-2"
            label="End date & time"
            label-for="EndDateAndTime"
          >
            <flat-pickr
              id="EndDateAndTIme"
              v-model="endTime"
              :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', minDate: beginTime }"
              class="form-control"
              placeholder="Select end date & tIme"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      v-if="rows.length"
      id="updateLinks"
      ok-title="Save"
      no-close-on-backdrop
      title="Update Meeting Links"
      @ok="updateLinks"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Meeting Link"
            label-for="updateMeetingLink"
          >
            <b-form-input
              id="updateMeetingLink"
              v-model="meeting_link"
              placeholder="Meeting Link"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Recording Link"
            label-for="updateRecordingLink"
          >
            <b-form-input
              id="updateRecordingLink"
              v-model="recording_link"
              placeholder="Recording Link"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      v-if="rows.length"
      id="ratingstable"
      size="lg"
      ok-only
      ok-title="Close"
      no-close-on-backdrop
      :title="'Ratings for '+rows[selectedRow].title"
    >
      <b-row>
        <b-col cols="12">
          <vue-good-table
            :columns="ratingtablefields"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
            :rows="ratings"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10','20','30']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :per-page="pageLength"
                    :total-rows="props.total"
                    :value="1"
                    class="mt-1 mb-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="updatementordescription"
      ok-only
      ok-title="Save"
      :title="'Update Description for '+mentorname"
      no-close-on-backdrop
      @ok="updateMentorDescription"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Description"
            label-for="newmentordescription"
          >
            <b-form-textarea
              id="newmentordescription"
              v-model="newmentordescription"
              placeholder="Update Mentor Description"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="addMentor"
      ok-only
      size="lg"
      ok-title="Save"
      title="Add Mentor"
      no-close-on-backdrop
      @ok="updateMentor"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Select Mentor"
            label-for="selectMentor"
          >
            <v-select
              id="selectMentor"
              v-model="selectedmentor"
              :options="mentors"
              :get-option-label="option => {
                if(option.users_customuser){
                  return option.users_customuser.full_name
                }
                return '-'
              }"
              :reduce="option => option.id"
              placeholder="Select Mentor"
            />
          </b-form-group>
          <b-form-group
            v-if="selectedmentor"
            label="Description"
            label-for="mentordescription"
          >
            <b-form-textarea
              id="mentordescription"
              v-model="mentordescription"
              placeholder="Enter Mentor Description"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <vue-good-table
            :columns="mentortablefields"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
            :rows="assignedmentors"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- Column: Action -->
              <span
                v-if="props.column.field === 'action'"
                class="d-flex"
              >
                <b-button
                  v-b-tooltip="'Edit Description'"
                  size="sm"
                  variant="outline-primary"
                  class="mr-1"
                  @click="updatementorid = props.row.originalIndex; mentorname = assignedmentors[props.row.originalIndex].programs_partnerstable.users_customuser.full_name; newmentordescription = assignedmentors[props.row.originalIndex].description; $bvModal.show('updatementordescription')"
                >
                  <feather-icon
                    icon="EditIcon"
                  />
                </b-button>
                <b-button
                  v-b-tooltip="'Delete'"
                  size="sm"
                  variant="outline-danger"
                  @click="deleteMentor(props.row.originalIndex);"
                >
                  <feather-icon
                    icon="TrashIcon"
                  />
                </b-button>
              </span>

              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10','20','30']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :per-page="pageLength"
                    :total-rows="props.total"
                    :value="1"
                    class="mt-1 mb-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="updateSchedule"
      ok-only
      size="lg"
      ok-title="Save"
      :ok-disabled="!beginTime || !endTime"
      title="Update Schedule"
      no-close-on-backdrop
      @ok="updateSchedule"
      @hide="beginTime = ''; endTime = ''"
    >
      <b-row>
        <!-- start date and time -->
        <b-col md="6">
          <b-form-group
            class="mb-2"
            label="Start date & time"
            label-for="StartDateAndTime"
          >
            <flat-pickr
              id="StartDateAndTime"
              v-model="beginTime"
              :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
              class="form-control"
              placeholder="Select start date & tIme"
            />
          </b-form-group>
        </b-col>
        <!-- end date and time -->
        <b-col md="6">
          <b-form-group
            class="mb-2"
            label="End date & time"
            label-for="EndDateAndTime"
          >
            <flat-pickr
              id="EndDateAndTIme"
              v-model="endTime"
              :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', minDate: beginTime }"
              class="form-control"
              placeholder="Select end date & tIme"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>

import {
  BBadge, BButton, BCol, BFormGroup, BFormInput, BFormTextarea, BFormSelect, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { formatDate } from '@/@core/utils/utils'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BBadge,
    vSelect,
    Treeselect,
    flatPickr,
  },
  data() {
    return {
      title: '',
      type: '',
      beginTime: '',
      endTime: '',
      meeting_link: '',
      recording_link: '',
      description: '',
      mentorname: '',
      updatementorid: null,
      selectedmentor: null,
      module: null,
      status: '',
      mutationLoading: false,
      participants: [],
      mentors: [],
      mentortablefields: [
        {
          label: 'Name',
          sortable: true,
          field(row) {
            return `${row.programs_partnerstable.users_customuser?.full_name}`
          },
        },
        {
          label: 'Description',
          sortable: true,
          field(row) {
            return `${row.description}`
          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: true,
        },
      ],
      ratingtablefields: [
        {
          label: 'Startup',
          sortable: true,
          field(row) {
            return `${row.programs_startupparticipant?.programs_applicantstable?.users_organizationtable?.title || '-'}`
          },
        },
        {
          label: 'Rating',
          sortable: true,
          field(row) {
            return `${row.rating}`
          },
        },
        {
          label: 'Remark',
          sortable: true,
          field(row) {
            return `${row.remarks}`
          },
        },
      ],
      assignedmentors: [],
      meetingTypeOptions: [
        {
          label: 'webinar',
          id: 1,
        },
        {
          label: 'one-on-one',
          id: 2,
        },
        {
          label: 'multi participant',
          id: 3,
        },
      ],
      mentordescription: '',
      newmentordescription: '',
      searchTerm: '',
      selectedRow: 0,
      selectedoperationid: 0,
      pageLength: 10,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Title',
          field: 'title', // Changed from id to field
          tdClass: 'text-capitalize',
        },
        {
          label: 'Module',
          field: 'programs_operationstable.title',
          filterOptions: {
            enabled: true,
            customFilter: true,
          },
        },
        {
          label: 'Mentors',
          field: 'mentors',
          formatter(row) {
            const temp = []
            row.mentor_assignments.forEach(dta => {
              temp.push(dta.programs_partnerstable.users_customuser?.full_name)
            })
            return temp
          },
        },
        {
          label: 'Date',
          field: 'date',
          width: '10rem',
        },
        {
          label: 'Ratings',
          sortable: false,
          field: 'ratings',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      selectedOperation: null,
      startups: [],
      partners: [],
      ratings: [],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Scheduled: 'light-primary',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
    startupParticipants: {
      get() {
        if (this.rows.length < 1) {
          return []
        }
        return 0
      },
      set(e) {
        this.rows[this.selectedRow].programs_meetparticipanttables = this.rows[this.selectedRow].programs_meetparticipanttables.filter(i => i.user_id_id !== null)
        this.rows[this.selectedRow].programs_meetparticipanttables.push(...e.map(val => ({
          organization_id_id: val,
          user_id_id: null,
          schedule_id_id: this.rows[this.selectedRow].id,
        })))
      },
    },
    partnerParticipants: {
      get() {
        if (this.rows.length < 1) {
          return []
        }
        return 0
      },
      set(e) {
        this.rows[this.selectedRow].programs_meetparticipanttables = this.rows[this.selectedRow].programs_meetparticipanttables.filter(i => i.organization_id_id !== null)
        this.rows[this.selectedRow].programs_meetparticipanttables.push(...e.map(val => ({
          organization_id_id: null,
          user_id_id: val,
          schedule_id_id: this.rows[this.selectedRow].id,
        })))
      },
    },

  },
  methods: {
    formatDate,
    getMentorFullName(mentor) {
      return mentor && mentor.programs_partnerstable && mentor.programs_partnerstable.users_customuser
        ? mentor.programs_partnerstable.users_customuser.full_name
        : 'N/A'
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.title,
        children: node.programs_operationstables,
      }
    },
    filterNormalizer(node) {
      return {
        id: node.title,
        label: node.title,
        children: node.programs_operationstables,
      }
    },

    toTimestamp(strDate) {
      const datum = Date.parse(strDate)
      return datum / 1000
    },
    addMeeting() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
        mutation {
          insert_programs_operationstable_one(object: {operations_id: "${this.module}", title: "${this.title}", description: "${this.description}", program_id: "${this.$route.params.id}", status: "Created", operations_type: "workshop"}) {
            id
          }
        }`,
        update: () => {
          this.showAlert('Workshop Added Successfully', 'success')
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
    handleUpdateTitleDescDate() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
        mutation {
          update_programs_operationstable_by_pk(pk_columns: {id: ${this.selectedoperationid}}, _set: {
            title: "${this.title}",
            description: "${this.description}",
            begindate_timestamp: "${this.beginTime}",
            enddate_timestamp: "${this.endTime}"

          }) {
            id
          }
        }
      `,
        update: () => {
          this.showAlert('Workshop Updated Successfully', 'success')
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
    updateParticipants() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation MyMutation($objects: [programs_meetparticipanttable_insert_input!]!) {
          delete_programs_meetparticipanttable(where: {schedule_id_id: {_eq: ${this.rows[this.selectedRow].id}}}) {
            affected_rows
          }
          insert_programs_meetparticipanttable(objects: $objects) {
            affected_rows
          }
        }`,
        variables: {
          objects: this.rows[this.selectedRow].programs_meetparticipanttables,
        },
        update: () => {
          this.showAlert('Participants Updated Successfully', 'success')
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
    updateMentor() {
      if (this.selectedmentor != null) {
        this.mutationLoading = true
        this.$apollo.mutate({
          mutation: gql`
          mutation {
            insert_mentor_assignment_one(object: {operations_id: "${this.selectedoperationid}", partner_id: "${this.selectedmentor}", description: "${this.mentordescription}"}) {
              id
            }
          }`,
          update: () => {
            this.showAlert('Mentor Added Successfully', 'success')
            this.$apollo.queries.rows.refetch()
            this.mutationLoading = false
            this.selectedmentor = null
            this.mentordescription = null
          },
          error: () => {
            this.showAlert('Error adding mentor', 'danger')
            this.mutationLoading = false
          },
        })
      } else {
        this.showAlert('Please select a mentor', 'danger')
      }
    },
    deleteMentor(mentorindex) {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
        mutation {
          update_mentor_assignment_by_pk(pk_columns: {id: ${this.rows[this.selectedRow].mentor_assignments[mentorindex].id} }, _set: {is_deleted: "true"}) {
            id
          }
        }`,
        update: () => {
          this.showAlert('Mentor Deleted Successfully', 'success')
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
          this.$bvModal.hide('addMentor')
        },
        error: () => {
          this.showAlert('Error deleting mentor', 'danger')
          this.mutationLoading = false
        },
      })
    },
    updateMentorDescription() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
        mutation {
          update_mentor_assignment_by_pk(pk_columns: {id: ${this.rows[this.selectedRow].mentor_assignments[this.updatementorid].id} }, _set: {description: "${this.newmentordescription}"}) {
            id
          }
        }`,
        update: () => {
          this.showAlert('Mentor Description Updated Successfully', 'success')
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
        error: () => {
          this.showAlert('Error updating mentor description', 'danger')
          this.mutationLoading = false
        },
      })
    },
    validateSchedule() {
      if (this.beginTime === this.endTime) {
        this.showAlert('Start and end times cannot be the same', 'danger')
        return false
      }
      return true
    },
    updateSchedule() {
      if (!this.validateSchedule()) {
        return
      }
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
          mutation UpdateSchedule($id: Int!, $beginTime: timestamptz!, $endTime: timestamptz!) {
            update_programs_operationstable_by_pk(
              pk_columns: { id: $id },
              _set: { begindate_timestamp: $beginTime, enddate_timestamp: $endTime }
            ) {
              id
            }
          }
        `,
        variables: {
          id: this.selectedoperationid,
          beginTime: this.beginTime,
          endTime: this.endTime,
        },
        update: () => {
          this.showAlert('Schedule Updated Successfully', 'success')
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
          this.$bvModal.hide('updateSchedule')
        },
        error: () => {
          this.showAlert('Error updating schedule', 'danger')
          this.mutationLoading = false
        },
      })
    },
    updateLinks() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
        mutation {
          update_programs_operationstable_by_pk(pk_columns: {id: ${this.selectedoperationid} }, _set: {meeting: "${this.meeting_link}", recording: "${this.recording_link}"}) {
            id
          }
        }`,
        update: () => {
          this.showAlert('Updated successfully', 'success')
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
        error: () => {
          this.showAlert('Error updating links', 'danger')
          this.mutationLoading = false
        },
      })
    },
    deleteWorkshop() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
        mutation {
          update_programs_operationstable_by_pk(pk_columns: {id: ${this.selectedoperationid} }, _set: {is_deleted: "true"}) {
            id
          }
        }`,
        update: () => {
          this.showAlert('Workshop Deleted Successfully', 'success')
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
        error: () => {
          this.showAlert('Error deleting workshop', 'danger')
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
        {
          programs_operationstable(order_by: {id: desc}, where: {program_id: {_eq: ${this.$route.params.id}}, operations_type: {_eq: "workshop"} ${this.$route.params.mid ? `, operations_id:{_eq: ${this.$route.params.mid}}` : ''}}) {
            id
            operations_id
            operations_type
            title
            meeting
            recording
            description
            begindate_timestamp
            enddate_timestamp
            programs_operationstable {
              id
              title
            }
            status
            programs_scheduletables {
              meeting_link
              recording_link
            }
            programs_assignmenttables {
              id
            }
            mentor_assignments {
              id
              description
              programs_partnerstable {
                id
                users_customuser {
                  full_name
                }
              }
            }
            ratings_tables_aggregate {
              aggregate {
                avg {
                  rating
                }
                count(distinct: false)
              }
            }
          }
        }`
      },
      update: data => data.programs_operationstable,
    },

    mentors: {
      query() {
        return gql`{
          programs_partnerstable(where: {program_id: {_eq: ${this.$route.params.id}}, mentor_assignments_aggregate: {count: {predicate: {_eq: 0}, filter: {operations_id: {_eq: ${this.selectedoperationid}}}}}}) {
            id
            users_customuser {
              full_name
            }
          }
        }`
      },
      update: data => data.programs_partnerstable,
    },

    ratings: {
      query() {
        return gql`
          query RatingTable{
           ratings_table(where: {operations_id: {_eq: ${this.selectedoperationid}}}) {
            programs_startupparticipant {
              programs_applicantstable {
                users_organizationtable {
                  title
                }
              }
            }
            rating
            remarks
          }
        }`
      },
      update: data => data.ratings_table,
    },

    operations: {
      query() {
        return gql`{
            programs_operationstable(where: {program_id: {_eq: ${this.$route.params.id}}, operations_type: {_eq: "SubPhase"}}) {
                  id
                  title
                }
              }`
      },
      update: data => data.programs_operationstable,
    },
    partners: {
      query() {
        return gql`{
          programs_partnerstable(where: {program_id: {_eq: ${this.$route.params.id} }}) {
            users_customuser {
              id
              full_name
            }
          }
        }`
      },
      update: data => data.programs_partnerstable.map(e => e.users_customuser),
    },
    startups: {
      query() {
        return gql`{
          programs_startupparticipants(where: {program_id: {_eq:  ${this.$route.params.id} }}) {
            programs_applicantstable {
              users_organizationtable {
                title
                id
              }
            }
          }
        }`
      },
      update: data => data.programs_startupparticipants.map(e => e.programs_applicantstable.users_organizationtable),
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
